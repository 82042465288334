import { useAnimation } from "framer-motion"
import { useEffect } from "react"

export function useFloatingEffect() {
    const controls = useAnimation()

    useEffect(() => {
        controls.start({
            y: [0, -20, 0],
            transition: {
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
            },
        })
    }, [controls])

    return {
        animate: controls,
    }
}
